/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2022-09-02 10:45:21
 * @LastEditors: xunmi muer3mu@126.com
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\common\api\user.js
 */

import domain from "./domain";
import { get, post } from "./axios";

const ActivityDetailsFormat = (data) => {
  if (data.rule) {
    try {
      let list = JSON.parse(data.rule),
        rule = [];

      list.forEach((item) => rule.push(item));

      if (rule.length) {
        data.rule = rule;
      } else {
        data.rule = null;
      }
    } catch (error) {}
  }

  if (data.privacy) {
    let list = JSON.parse(data.privacy),
      privacy = [];

    list.forEach((item) => item && item.trim() && privacy.push(item.trim()));

    if (privacy.length) {
      data.privacy = privacy;
    } else {
      data.privacy = null;
    }
  }

  data.accountType = (() => {
    if (data.accountType) {
      let queue = JSON.parse(data.accountType),
        object = {};
      queue.forEach((item) => (object[item.accountType] = item));
      return object;
    }

    return {};
  })();

  data.awardType = (() => {
    if (data.awardType) {
      let queue = JSON.parse(data.awardType),
        object = {};
      queue.forEach((item) => (object[item.awardType] = item));
      return object;
    }

    return {};
  })();

  data.verification = (() => {
    if (data.verification) {
      let queue = JSON.parse(data.verification),
        object = {};
      queue.forEach((item) => (object[item.verification] = item));
      return object;
    }

    return {};
  })();

  data.activityType = (() => {
    if (data.activityType) {
      let queue = JSON.parse(data.activityType),
        object = {};
      queue.forEach((item) => (object[item.activityType] = item));
      return object;
    }

    return {};
  })();

  return data;
};

const ActivityRecordFormat = (list) => {
  if (list && list.length) {
    list.forEach((item) => {
      if (item.datas) {
        item.datas = JSON.parse(item.datas);
        item.accountData = JSON.parse(item.accountData || "[]");

        let data = {};

        item.accountData.forEach((i) => {
          data[i.type] = i;
        });

        item.accountData = data;
      }
    });
  }

  return list;
};

const activity = {
  details(data, bag) {
    return get(`${domain.mobile}/activity/{code}`, data, ["code"], ActivityDetailsFormat, bag);
  },
  check(data, bag) {
    return post(`${domain.mobile}/activity/check`, data, ["accountData"], null, bag);
  },
  qualifications(data, bag) {
    return post(`${domain.mobile}/activity/qualifications`, data, ["accountData"], null, bag);
  },
  luck(data, bag) {
    return post(`${domain.mobile}/activity/getLuck`, data, ["activityId", "mobileUserId"], null, bag);
  },
  record(data, bag) {
    return get(`${domain.mobile}/activity/getReceive`, data, ["activityId", "openId"], ActivityRecordFormat, bag);
  },
  subject(data, bag) {
    return get(`${domain.mobile}/activity/getTopic/{code}`, data, ["code"], null, bag);
  },
  datas(data, bag) {
    return get(`${domain.mobile}/activity/datas/{activityId}`, data, null, null, bag);
  },
  getInfo: {
    addInformationData(data, bag) {
      return post(`${domain.mobile}/activity/addInformationData`, data, null, null, bag);
    },
    mobileCaptchaImage(data, bag) {
      return post(`${domain.mobile}/mobileCaptchaImage`, data, null, null, bag);
    }
  },
  poster: {
    poster(data, bag) {
      return get(`${domain.mobile}/activity/poster/{posterId}`, data, ["posterId"], null, bag);
    }
  }
};

export default activity;
