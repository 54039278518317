/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:09
 * @LastEditTime: 2021-11-15 09:07:25
 * @LastEditors: Please set LastEditors
 * @Description: 配置接口请求域名
 * @FilePath: \SuperMall\src\common\api\domain.js
 */

import { setResponseFormat } from './axios';

const domain =
{
	mock: '/mock',
	mobile: '/saasApi/publicMobile'
};

const formatMock = (response) =>
{
	return {
		status: 200,
		message: '数据请求成功',
		data: response
	};
};

const formatBase = (response) =>
{
	let object = {
			status: response.code,
			message: response.msg
		};

	object.data = response.data || response.rows;

	for (let key in response)
	{
		if (!~['data', 'rows', 'code', 'msg'].indexOf(key))
		{
			object[ key ] = response[ key ];
		}
	}

	return object;
};

setResponseFormat(domain.mock + '/', formatMock);
setResponseFormat(domain.mobile + '/', formatBase);

export default domain;