/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:02
 * @LastEditTime: 2021-11-08 11:37:38
 * @LastEditors: Please set LastEditors
 * @Description: 接口索引页
 * @FilePath: \SuperMall\src\common\api\index.js
 */
const ua = navigator.userAgent.toLowerCase();

const System =
{
	IsFreeBSD: ua.indexOf('freebsd') > -1,
	IsLinux: ua.indexOf('linux') > -1,
	IsMacOS: ua.indexOf('mac os') > -1,
	IsSolaris: ua.indexOf('sunos') > -1,
	IsWindows: ua.indexOf('windows') > -1,
	IsSymbianOS: ua.indexOf('symbianos') > -1,
	IsBlackBerry: ua.indexOf('blackberry') > -1,
	IsAndroid: ua.indexOf('android') > -1
};

const Browser =
{
	IsWeChat: ua.indexOf('micromessenger') > -1,
	IsQQBrowser: ua.indexOf('mqqbrowser') > -1,
	IsQQ: ua.indexOf('qq/') > -1 && ua.indexOf('mqqbrowser') == -1,
	IsTrident: ua.indexOf('trident') > -1,
	IsMSIE: ua.indexOf('msie') > -1,
	IsPresto: ua.indexOf('presto') > -1,
	IsWebKit: ua.indexOf('applewebkit') > -1,
	IsGecko: ua.indexOf('gecko') > -1 && ua.indexOf('khtml') == -1
};

const Device =
{
	IsAndroid: ua.indexOf('android') > -1,
	IsIPod: ua.indexOf('ipod') > -1,
	IsIPhone: ua.indexOf('iphone') > -1 && ua.indexOf('ipod') == -1 && ua.indexOf('ipad') == -1,
	IsIPad: ua.indexOf('ipad') > -1,
	IsMobile: (ua.indexOf('mobile') > -1 || ua.indexOf('mobi') > -1 || ua.indexOf('symbianos') > -1) && ua.indexOf('ipod') == -1 && ua.indexOf('ipad') == -1,
	IsTablet: ua.indexOf('tablet') > -1,
	IsDesktop: ua.indexOf('macintosh') > -1 || ua.indexOf('windows nt') > -1
};

class GUID
{
	static New(upper)
	{
		return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c =>
		{
			let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return !upper ? v.toString(16) : v.toString(16).toUpperCase();
		});
	}
}

const RequestQuery = (key, local) =>
{
	let search = window.location.search.replace('?', '').split('&');
	
	for (let i = 0, l = search.length; i < l; i++)
	{
		let kv = search[ i ].split('=');

		if (kv[ 0 ].toLowerCase() === key.toLowerCase())
		{
			let value = kv[ 1 ] !== undefined ? kv[ 1 ] : true;

			if (local)
			{
				localStorage.setItem(key.toLowerCase(), value);
			}

			return value;
		}
	}

	return localStorage.getItem(key.toLowerCase());
}

export default {
	GUID,
	System,
	Device,
	Device,
	RequestQuery
};