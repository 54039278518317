/*
 * @Author: 荀厥樟
 * @Date: 2021-06-29 09:18:02
 * @LastEditTime: 2021-11-09 11:10:56
 * @LastEditors: Please set LastEditors
 * @Description: 接口索引页
 * @FilePath: \SuperMall\src\common\api\index.js
 */

import base from './base';
import wechat from './wechat';
import activity from './activity';
import question from './question';

const getAuthorizationHeaders = () =>
{
	let headers = {},
		user = JSON.parse(sessionStorage.getItem('SASS_LIJIANJIN_SESSION') || '{}');

	if (user && user.access_token)
	{
		headers[ 'Authorization' ] = 'Bearer ' + user.access_token;
	}

	return headers;
}

export default {
	base,
	wechat,
	activity,
	question,
	getAuthorizationHeaders
};