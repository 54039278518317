/*
 * @Author: your name
 * @Date: 2021-08-05 16:52:04
 * @LastEditTime: 2021-11-09 11:57:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Basic\src\common\api\user.js
 */

import domain from './domain';
import { post } from './axios';

const SubjectIndexFormat = (data) =>
{
	if (data && data.length)
	{
		data.forEach(item =>
		{
			let object = JSON.parse(item.subject),
				copy = {};

			for (let key in object)
			{
				copy[key.toUpperCase()] = object[key];
			}
			
			item.subject = copy;
			item.answer = item.answer.toUpperCase();
			item.selected = '';
		});
	}

	return data;
};

const question =
{
	index(data, bag)
	{
		return post(`${domain.mobile}/activity/subjectList`, data, [ 'activityCode' ], SubjectIndexFormat, bag);
	}
}

export default question;